import { defineComponent as _defineComponent } from 'vue'
import {
  IScopeTwoGeneratedEnergiesRequest,
  IScopeTwoGridEmissionFactor,
  IScopeTwoParameters,
} from "@/models/ScopeTwoEmissions";
import AccountModule from "@/store/modules/Account";
import MetadataModule from "@/store/modules/Metadata";
import {
  buildAndFormatDataStringToNumber,
  formatNumber,
  removeNumberFormat,
  validateInputNumberType,
} from "@/utils/helpers/buildDataFormat";
import { isCSV } from "@/utils/helpers/checkFileType";
import { MONTHS } from "@ems/constants";
import S2RPModule from "@ems/containers/Dashboard/TheForm/S2RP.module";
import DeviceModule from "@ems/containers/Dashboard/TheForm/Device.module";
import { ElMessage } from "element-plus/lib/components/message";
import { configure, Field, Form } from "vee-validate";
import { computed, onMounted, onUnmounted, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import { number, object, string, array } from "yup";
import {
  endDateOfMonth,
  startDateOfMonth,
} from "@/utils/helpers/convertDateTime";
import BaseSvgIcon from "@/components/BaseSvgIcon.vue";
import S2GeneratedEnergyModule from "@ems/containers/ScopeTwoEmission/Table/S2GeneratedEnergy.module";
interface RawFile {
  name: string;
  url: string;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'Generated_Energy.Form',
  emits: ["resetFormGeneratedEnergyOnClickRow"],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const emits = __emit;
configure({
  validateOnBlur: false,
  validateOnChange: false,
  validateOnInput: false,
  validateOnModelUpdate: true,
});
const facilityField = ref();
const deviceField = ref();
const countryField = ref();
const generatedEnergyForm = ref();
const selectFormField = ref();
const disableInputDevice = ref(true);
const months = reactive<any>({ data: MONTHS });
let listArrImage = [] as any;
const schema = object({
  Country: string().required("Country is a required field").label("Country"),
  FacilityId: string()
    .required("Facility is a required field")
    .label("Facility"),
  MonthRange: array().required().nullable().label("MonthRange"),
  Value: string().required().label("Total Volume of Generation"),
  Device: string().when("DeviceId", {
    is: "All",
    then: string().required("Device is a required field"),
  }),
});
const uploadRef = ref<any>();
const isReadonlyGeneratedEnergyForm = computed(
  () => S2RPModule.isReadonlyGeneratedEnergyFormGetter
);
const generatedEnergyTotalGetter = computed(
  () => S2RPModule.generatedEnergyTotalGetter
);
const isLoadingGeneratedEnergyTotal = computed(
  () => S2RPModule.isLoadingGeneratedEnergyTotalGetter
);
const router = useRouter();

const Authorization = computed(
  () => AccountModule.getUserInfo?.authorizationString + ""
);

const dataCountries = computed(() => MetadataModule.dataCountriesGetter);
const facilities = reactive<any>({ data: [] });

const request = reactive<any>({ params: {} });
const collectData = reactive<any>({
  data: {
    Country: "",
    FacilityId: "",
    Year: "",
  },
});
onUnmounted(() => {
  S2RPModule.emptyGeneratedEnergyTotal();
  S2RPModule.emptyGeneratedRequest();
  S2RPModule.emptyGeneratedRequestDetail();
  S2RPModule.toggleGeneratedEnergyButtonType(false);
  S2RPModule.setGeneratedTotalFromFacility({} as IScopeTwoGridEmissionFactor);
});
const energyGeneratedEmissionFactor = computed(
  () => S2RPModule.energyGeneratedEmissionFactorGetter
);
const dataDevices = computed(() => DeviceModule.dataDevicesGetter);
onMounted(() => {
  const facilitiesGetter = MetadataModule.dataFacilitiesGetter;
  facilities.data = facilitiesGetter.filter(
    (fac) => fac.CountryId == energyGeneratedEmissionFactor.value.Country
  );
  collectData.data = {
    ...collectData.data,
    Country: energyGeneratedEmissionFactor.value.Country,
    FacilityId: energyGeneratedEmissionFactor.value.FacilityId,
    Year: energyGeneratedEmissionFactor.value.Year,
  };
  S2GeneratedEnergyModule.fetchS2RenewablePercentages();
  if (
    collectData.data.FacilityId &&
    collectData.data.Country &&
    collectData.data.Year
  ) {
    request.params = {
      FacilityId: collectData.data.FacilityId,
      Year: collectData.data.Year,
      Country: collectData.data.Country,
    } as IScopeTwoParameters;
    S2RPModule.getGeneratedEnergyRequestAction(request.params);
    S2RPModule.getGeneratedEnergyTotalAction(request.params);
    DeviceModule.getDevices({ FacilityId: collectData.data.FacilityId });
  }
});
const handleChangeCountry = async (value: any) => {
  collectData.data = {
    ...collectData.data,
    Country: value,
  };
  facilityField.value.reset();
  deviceField.value.reset();
  const facilitiesGetter = MetadataModule.dataFacilitiesGetter;
  facilities.data = facilitiesGetter.filter((fac) => fac.CountryId == value);
};

const handleChangeFacility = async (value: any) => {
  collectData.data = {
    ...collectData.data,
    FacilityId: value,
  };
  DeviceModule.getDevices({ FacilityId: value });
  deviceField.value.reset();
  if (collectData.data.Year) {
    request.params = {
      FacilityId: value,
      Year: collectData.data.Year,
    } as IScopeTwoParameters;
    await S2RPModule.getGeneratedEnergyRequestAction(request.params);
    await S2RPModule.getGeneratedEnergyTotalAction(request.params);
  }
};

const handleChangeDevice = async (value: any) => {
  if (value === "All") {
    disableInputDevice.value = false;
  } else {
    disableInputDevice.value = true;
  }
};

const handleResetForm = (values: any, resetForm: any) => {
  emits("resetFormGeneratedEnergyOnClickRow", generatedEnergyForm.value);
  S2RPModule.toggleGeneratedEnergyButtonType(false);
};

const beforeUploadFile = (file: any) => {
  const checkCSV = isCSV(file.name);
  const isLt2M = file.size / 1024 / 1024 < 2;

  if (!checkCSV) {
    ElMessage.error("File must be .csv, .xlsx, .xls format!");
  }
  if (!isLt2M) {
    ElMessage.error("File size can not exceed 2MB!");
  }
  return checkCSV && isLt2M;
};

const onSubmit = async (values: any, actions: any) => {
  const requestCreateGenerated = {
    FacilityId: values.FacilityId,
    FromDate: startDateOfMonth(values.MonthRange[0]),
    ToDate: endDateOfMonth(values.MonthRange[1]),
    ToTalVolume:
      values.Target === 0
        ? buildAndFormatDataStringToNumber(values.Value)
        : buildAndFormatDataStringToNumber(values.Value) * 1000,
    UnitEnergy: values.Target,
  } as IScopeTwoGeneratedEnergiesRequest;

  if (values.DeviceId === "All") {
    const data = await DeviceModule.createDevices({
      FacilityId: values.FacilityId,
      Name: values.Device,
    });
    requestCreateGenerated.DeviceId = data.Id;
  } else {
    requestCreateGenerated.DeviceId = values.DeviceId;
  }
  await createGeneratedEnergy(requestCreateGenerated);
  S2GeneratedEnergyModule.fetchS2RenewablePercentages();
  actions.resetForm();
  uploadRef.value!.submit();
  listArrImage = [];
};
const createGeneratedEnergy = async (
  requestCreateGenerated: IScopeTwoGeneratedEnergiesRequest
) => {
  const data = await S2GeneratedEnergyModule.createGeneratedEnergy(
    requestCreateGenerated
  );
  S2GeneratedEnergyModule.getGeneratedEnergies();
  if (listArrImage?.length > 0) {
    parseDataRequestFile(data.Id, listArrImage);
  }
};
const parseDataRequestFile = async (
  generatedEnergyId: string,
  listArrImage: any
) => {
  const newListForm = listArrImage.map((item: any, index: any) => {
    index = new FormData();
    index.append("file", item);
    return index;
  });

  const fileUploads = newListForm.map((item: any) =>
    S2GeneratedEnergyModule.uploadFileGeneratedEnergy({
      Id: generatedEnergyId,
      data: item,
    })
  );
  await Promise.all(fileUploads);
  return newListForm;
};
const onBlurValueField = (value: any, setFieldValue: any) => {
  if (!value || value.length < 1) return;
  const result = formatNumber(Number(value), 0);
  setFieldValue("Value", result);
};
const onFocusValueField = (value: any, setFieldValue: any) => {
  if (!value || value.length < 1) return;
  var result = removeNumberFormat(value);
  setFieldValue("Value", result);
};
const onChangeValueField = (value: any, setFieldValue: any) => {
  const result = validateInputNumberType(parseFloat(value));
  setFieldValue("Value", result);
};

const handleUploadImageSuccess = (res: any, file: any, field: any) => {
  console.log(URL.createObjectURL(file.raw));
};
const beforeUploadImage = (file: any) => {
  const isJPG = true;
  const isLt2M = file.size / 1024 / 1024 < 2;

  if (!isJPG) {
    ElMessage.error("Avatar picture must be JPG format!");
  }
  if (!isLt2M) {
    ElMessage.error("Avatar picture size can not exceed 2MB!");
  }
  return isJPG && isLt2M;
};
const onChangeFile = (file: any) => {
  listArrImage.push(file.raw);
};
const handleRemove = (file: any, uploadFiles: any) => {
  const arr = uploadFiles.map((item: any) => {
    return item.raw;
  });
  listArrImage = arr;
};

const __returned__ = { emits, facilityField, deviceField, countryField, generatedEnergyForm, selectFormField, disableInputDevice, months, get listArrImage() { return listArrImage }, set listArrImage(v) { listArrImage = v }, schema, uploadRef, isReadonlyGeneratedEnergyForm, generatedEnergyTotalGetter, isLoadingGeneratedEnergyTotal, router, Authorization, dataCountries, facilities, request, collectData, energyGeneratedEmissionFactor, dataDevices, handleChangeCountry, handleChangeFacility, handleChangeDevice, handleResetForm, beforeUploadFile, onSubmit, createGeneratedEnergy, parseDataRequestFile, onBlurValueField, onFocusValueField, onChangeValueField, handleUploadImageSuccess, beforeUploadImage, onChangeFile, handleRemove, get IScopeTwoGeneratedEnergiesRequest() { return IScopeTwoGeneratedEnergiesRequest }, get IScopeTwoGridEmissionFactor() { return IScopeTwoGridEmissionFactor }, get IScopeTwoParameters() { return IScopeTwoParameters }, get AccountModule() { return AccountModule }, get MetadataModule() { return MetadataModule }, get buildAndFormatDataStringToNumber() { return buildAndFormatDataStringToNumber }, get formatNumber() { return formatNumber }, get removeNumberFormat() { return removeNumberFormat }, get validateInputNumberType() { return validateInputNumberType }, get isCSV() { return isCSV }, get MONTHS() { return MONTHS }, get S2RPModule() { return S2RPModule }, get DeviceModule() { return DeviceModule }, get ElMessage() { return ElMessage }, get configure() { return configure }, get Field() { return Field }, get Form() { return Form }, computed, onMounted, onUnmounted, reactive, ref, get useRouter() { return useRouter }, get number() { return number }, get object() { return object }, get string() { return string }, get array() { return array }, get endDateOfMonth() { return endDateOfMonth }, get startDateOfMonth() { return startDateOfMonth }, BaseSvgIcon, get S2GeneratedEnergyModule() { return S2GeneratedEnergyModule } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})