import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-row" }
const _hoisted_2 = { class: "flex flex-col mr-20" }
const _hoisted_3 = { class: "flex mb-6 space-x-4" }
const _hoisted_4 = { class: "inputfield inputfield__underline w-1/2" }
const _hoisted_5 = { class: "inputfield inputfield__underline w-1/2" }
const _hoisted_6 = { class: "inputfield inputfield__underline w-1/2" }
const _hoisted_7 = { class: "flex mb-6 space-x-4" }
const _hoisted_8 = { class: "flex mb-6 space-x-4" }
const _hoisted_9 = { class: "flex space-x-4" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { class: "flex" }
const _hoisted_12 = { class: "mb-3" }
const _hoisted_13 = { class: "el-icon el-icon--upload" }
const _hoisted_14 = { class: "flex justify-end items-center border-t-2 2xl:space-x-5 space-x-3 2xl:py-5 py-3 mt-10" }
const _hoisted_15 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_upload = _resolveComponent("el-upload")!

  return (_openBlock(), _createBlock($setup["Form"], {
    as: "el-form",
    "label-position": "top",
    "validation-schema": $setup.schema,
    "initial-values": { ...$setup.energyGeneratedEmissionFactor, Target: 1 },
    onSubmit: $setup.onSubmit,
    ref: "selectFormField"
  }, {
    default: _withCtx(({ isSubmitting, setFieldValue }) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode($setup["Field"], {
                name: "Country",
                ref: "countryField"
              }, {
                default: _withCtx(({ value, field, errorMessage }) => [
                  _createVNode(_component_el_form_item, {
                    class: "inputfield",
                    error: errorMessage,
                    label: "Country"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_select, _mergeProps({ class: "2xl:w-full lg:w-32 xl:w-48 md:w-52" }, field, {
                        "model-value": value,
                        placeholder: "Select",
                        onChange: (value) => $setup.handleChangeCountry(value)
                      }), {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.dataCountries, (item) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              class: "flex items-center",
                              key: item.Value,
                              label: item.Name,
                              value: item.Value
                            }, null, 8, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 2
                      }, 1040, ["model-value", "onChange"])
                    ]),
                    _: 2
                  }, 1032, ["error"])
                ]),
                _: 1
              }, 512)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode($setup["Field"], {
                name: "FacilityId",
                ref: "facilityField"
              }, {
                default: _withCtx(({ value, field, errorMessage }) => [
                  _createVNode(_component_el_form_item, {
                    class: "inputfield",
                    error: errorMessage,
                    label: "Facility"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_select, _mergeProps({ class: "2xl:w-full lg:w-32 xl:w-48 md:w-52" }, field, {
                        "model-value": value,
                        placeholder: "Select",
                        onChange: (value) => $setup.handleChangeFacility(value)
                      }), {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.facilities.data, (item) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              class: "flex items-center",
                              key: item.Value,
                              label: item.Name,
                              value: item.Value
                            }, null, 8, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 2
                      }, 1040, ["model-value", "onChange"])
                    ]),
                    _: 2
                  }, 1032, ["error"])
                ]),
                _: 1
              }, 512)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode($setup["Field"], {
                name: "DeviceId",
                ref: "deviceField"
              }, {
                default: _withCtx(({ value, field, errorMessage }) => [
                  _createVNode(_component_el_form_item, {
                    class: "inputfield",
                    error: errorMessage,
                    label: "Device"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_select, _mergeProps({ class: "2xl:w-full lg:w-32 xl:w-48 md:w-52" }, field, {
                        "model-value": value,
                        placeholder: "Select",
                        onChange: (value) => $setup.handleChangeDevice(value)
                      }), {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.dataDevices, (item) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              class: "flex items-center",
                              key: item.Id,
                              label: item.Name,
                              value: item.Id
                            }, null, 8, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 2
                      }, 1040, ["model-value", "onChange"])
                    ]),
                    _: 2
                  }, 1032, ["error"])
                ]),
                _: 1
              }, 512)
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _cache[0] || (_cache[0] = _createElementVNode("label", { class: "el-form-item__label" }, "PV Generation Generation Duration:", -1)),
            _createVNode($setup["Field"], { name: "MonthRange" }, {
              default: _withCtx(({ value, field, errorMessage }) => [
                _createVNode(_component_el_form_item, {
                  class: "inputfield inline-block mr-12 text-sm range-editor",
                  error: errorMessage
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_date_picker, _mergeProps({ class: [false && 'disabled-input'] }, field, {
                      "model-value": value,
                      type: "monthrange",
                      editable: false,
                      "disabled-date": (time) => time.getTime() > Date.now(),
                      placeholder: "",
                      format: "MM/YYYY",
                      "start-placeholder": "Start month",
                      "range-separator": "To:",
                      "end-placeholder": "End month"
                    }), null, 16, ["model-value", "disabled-date"])
                  ]),
                  _: 2
                }, 1032, ["error"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode($setup["Field"], { name: "Value" }, {
              default: _withCtx(({ value, field, errorMessage }) => [
                _createVNode(_component_el_form_item, {
                  class: "inputfield inline withUnit",
                  error: errorMessage,
                  label: "Total Volume of Generation: "
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, _mergeProps({
                      controls: false,
                      class: ['w-56, ml-2'],
                      placeholder: ""
                    }, field, {
                      "model-value": value,
                      onBlur: () => $setup.onBlurValueField(value, setFieldValue),
                      onFocus: () => $setup.onFocusValueField(value, setFieldValue),
                      onInput: 
                    (val) =>
                      $setup.onChangeValueField(val, setFieldValue)
                  
                    }), null, 16, ["model-value", "onBlur", "onFocus", "onInput"])
                  ]),
                  _: 2
                }, 1032, ["error"])
              ]),
              _: 2
            }, 1024),
            _createVNode($setup["Field"], { name: "Target" }, {
              default: _withCtx(({ value, field, errorMessage }) => [
                _createVNode(_component_el_form_item, {
                  class: "pb-1",
                  error: errorMessage
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_radio_group, _mergeProps({ class: "flex flex-row" }, field, { "model-value": value }), {
                      default: _withCtx(() => [
                        _createVNode(_component_el_radio, { label: 1 }, {
                          default: _withCtx(() => _cache[1] || (_cache[1] = [
                            _createTextVNode("MWh")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_el_radio, { label: 0 }, {
                          default: _withCtx(() => _cache[2] || (_cache[2] = [
                            _createTextVNode("kWh")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 2
                    }, 1040, ["model-value"])
                  ]),
                  _: 2
                }, 1032, ["error"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_9, [
            (!$setup.disableInputDevice)
              ? (_openBlock(), _createBlock($setup["Field"], {
                  key: 0,
                  name: "Device"
                }, {
                  default: _withCtx(({ value, field, errorMessage }) => [
                    _createVNode(_component_el_form_item, {
                      class: "inputfield inline withUnit",
                      error: errorMessage,
                      label: "Device Name: "
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, _mergeProps({
                          controls: false,
                          class: ['w-10, ml-2'],
                          placeholder: ""
                        }, field, { "model-value": value }), null, 16, ["model-value"])
                      ]),
                      _: 2
                    }, 1032, ["error"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          (!$setup.disableInputDevice)
            ? (_openBlock(), _createElementBlock("p", _hoisted_10, " *Please key in a name for the new device "))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createVNode($setup["Field"], { name: "imageUrl" }, {
              default: _withCtx(({ field, errorMessage }) => [
                _createVNode(_component_el_form_item, {
                  class: "mb-0",
                  error: errorMessage,
                  label: "Upload the document for the Retired RECs here*:"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_upload, {
                      class: "custom-file-list-image",
                      drag: "",
                      limit: 5,
                      multiple: "",
                      "auto-upload": false,
                      ref: "uploadRef",
                      "on-success": (res, file) => $setup.handleUploadImageSuccess(res, file, field),
                      "before-upload": $setup.beforeUploadImage,
                      "on-change": (res, file) => $setup.onChangeFile(res),
                      "on-remove": $setup.handleRemove
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("i", _hoisted_13, [
                          _createVNode($setup["BaseSvgIcon"], {
                            class: "text-black inline-block group-hover:text-indino-primary w-3 h-3",
                            name: "icon-download"
                          })
                        ]),
                        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "el-upload__text" }, "Please upload your files here", -1))
                      ]),
                      _: 2
                    }, 1032, ["on-success", "on-change"])
                  ]),
                  _: 2
                }, 1032, ["error"])
              ]),
              _: 1
            })
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("button", {
          type: "submit",
          disabled: isSubmitting,
          class: "btn btn--green-primary"
        }, " Update ", 8, _hoisted_15)
      ])
    ]),
    _: 1
  }, 8, ["validation-schema", "initial-values"]))
}