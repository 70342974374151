import { defineComponent as _defineComponent } from 'vue'
import { IUploadScopeDTO } from "@/models/FormData";
import {
  IScopeTwoGridEmissionFactor,
  IScopeTwoParameters,
} from "@/models/ScopeTwoEmissions";
import AccountModule from "@/store/modules/Account";
import MetadataModule from "@/store/modules/Metadata";
import {
  buildAndFormatDataScope,
  formatNumber,
  removeNumberFormat,
  validateInputNumberType,
} from "@/utils/helpers/buildDataFormat";
import { isCSV } from "@/utils/helpers/checkFileType";
import { MONTHS } from "@ems/constants";
import S2RPModule from "@ems/containers/Dashboard/TheForm/S2RP.module";
import { ElMessage } from "element-plus/lib/components/message";
import { isBuffer } from "lodash";
import { configure, Field, FieldArray, Form } from "vee-validate";
import { computed, onUnmounted, reactive, ref, onMounted, watch } from "vue";
import { number, object, string } from "yup";
import groupBy from "lodash/groupBy";

interface RawFile {
  name: string;
  url: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Energy_Loads.Form',
  emits: ["resetFormEnergyLoadsOnClickRow"],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const emits = __emit;
configure({
  validateOnBlur: false,
  validateOnChange: false,
  validateOnInput: false,
  validateOnModelUpdate: true,
});
const facilityField = ref();
const countryField = ref();
const yearField = ref();
const gridEmissionFactorField = ref();
const energyLoadsForm = ref();
const selectFormField = ref();
let listArrImage = [] as any;
const months = reactive<any>({ data: MONTHS });
let fileList = ref<any[]>([]);
const MonthType = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const energyLoadsDocument = computed(
  () => S2RPModule.energyLoadsDocumentGetter
);
const schema = object({
  Country: string().required("Country is a required field").label("Country"),
  FacilityId: string()
    .required("Facility is a required field")
    .label("Facility"),
  Year: number().required("Year is a required field").nullable(),
});
const isReadonlyEnergyLoadsForm = computed(
  () => S2RPModule.isReadonlyEnergyLoadsFormGetter
);
const energyLoadsTotalGetter = computed(
  () => S2RPModule.energyLoadsTotalGetter
);
const isLoadingEnergyLoadsTotal = computed(
  () => S2RPModule.isLoadingEnergyLoadsTotalGetter
);
const energyLoadGridEmissionFactorGetter = computed(
  () => S2RPModule.energyLoadGridEmissionFactorGetter
);
const Authorization = computed(
  () => AccountModule.getUserInfo?.authorizationString + ""
);
const dataCountries = computed(() => MetadataModule.dataCountriesGetter);
const facilities = reactive<any>({ data: [] });

const request = reactive<any>({ params: {} });
const collectData = reactive<any>({
  data: {
    Country: "",
    FacilityId: "",
    Year: "",
    GridEmissionFactor: "0",
  },
});

onMounted(() => {
  const facilitiesGetter = MetadataModule.dataFacilitiesGetter;
  facilities.data = facilitiesGetter.filter(
    (fac) => fac.CountryId == energyLoadGridEmissionFactorGetter.value.Country
  );
  collectData.data = {
    ...collectData.data,
    Country: energyLoadGridEmissionFactorGetter.value.Country,
    FacilityId: energyLoadGridEmissionFactorGetter.value.FacilityId,
    Year: energyLoadGridEmissionFactorGetter.value.Year,
  };
  if (
    collectData.data.FacilityId &&
    collectData.data.Country &&
    collectData.data.Year
  ) {
    request.params = {
      FacilityId: collectData.data.FacilityId,
      Year: collectData.data.Year,
      Country: collectData.data.Country,
    } as IScopeTwoParameters;
    S2RPModule.getEnergyLoadRequestAction(request.params);
    S2RPModule.getEnergyLoadFileAndDocument(request.params);
  }
});
watch(energyLoadsDocument, () => {
  energyLoadsDocument.value.forEach((x: any, index: number) => {
    if (x.Files?.length > 0) {
      fileList.value[x.MonthType] = parseFile(x.Files);
    }
  });
});
const parseFile = (data: any) => {
  return data.map((file: any) => {
    return {
      Id: file.Id,
      ModelId: file.ModelId,
      name: file.Name,
      url: file.Content,
    };
  });
};
onUnmounted(() => {
  S2RPModule.emptyEnergyLoadTotal();
  S2RPModule.emptyEnergyLoadsRequest();
  S2RPModule.emptyEnergyLoadsRequestDetail();
  S2RPModule.toggleEnergyLoadsButtonType(false);
  S2RPModule.setEnergyLoadTotalFromFacility({} as IScopeTwoGridEmissionFactor);
});
const handleChangeCountry = async (value: any) => {
  energyLoadsForm.value.resetForm({
    values: {
      MonthValue: new Array(12).fill(""),
    },
  });
  collectData.data = {
    ...collectData.data,
    Country: value,
  };
  facilityField.value.reset();
  S2RPModule.setEnergyLoadTotalFromFacility({
    FacilityId: "",
  } as IScopeTwoGridEmissionFactor);
  const facilitiesGetter = MetadataModule.dataFacilitiesGetter;
  facilities.data = facilitiesGetter.filter((fac) => fac.CountryId == value);
};

const handleChangeFacility = async (value: any) => {
  energyLoadsForm.value.resetForm({
    values: {
      MonthValue: new Array(12).fill(""),
    },
  });
  collectData.data = {
    ...collectData.data,
    FacilityId: value,
  };
  if (collectData.data.Year) {
    request.params = {
      FacilityId: value,
      Year: collectData.data.Year,
    } as IScopeTwoParameters;
    await S2RPModule.getEnergyLoadRequestAction(request.params);
    await S2RPModule.getEnergyLoadFileAndDocument(request.params);
    fileList.value = [];
  }
};

const handleChangeYear = async (value: any) => {
  energyLoadsForm.value.resetForm({
    values: {
      MonthValue: new Array(12).fill(""),
    },
  });
  collectData.data = {
    ...collectData.data,
    Year: value,
  };
  if (collectData.data.FacilityId) {
    S2RPModule.storageCurrentOptionYearEnergyLoads(value);
    request.params = {
      FacilityId: collectData.data.FacilityId,
      Year: value,
    } as IScopeTwoParameters;
    await S2RPModule.getEnergyLoadRequestAction(request.params);
    await S2RPModule.getEnergyLoadFileAndDocument(request.params);
    fileList.value = [];
  }
};
const handleResetForm = (values: any, resetForm: any) => {
  resetForm({
    values: {
      ...values,
      MonthValue: new Array(12).fill(""),
    },
  });
  emits("resetFormEnergyLoadsOnClickRow", energyLoadsForm.value);
  S2RPModule.toggleEnergyLoadsButtonType(false);
};
const beforeUploadFile = (file: any) => {
  const checkCSV = isCSV(file.name);
  const isLt2M = file.size / 1024 / 1024 < 2;

  if (!checkCSV) {
    ElMessage.error("File must be .csv, .xlsx, .xls format!");
  }
  if (!isLt2M) {
    ElMessage.error("File size can not exceed 2MB!");
  }
  return checkCSV && isLt2M;
};

const handlePreview = (uploadFile: any) => {
  uploadFile.url
    ? window.open(uploadFile.url)
    : window.open(URL.createObjectURL(uploadFile.raw));
};

const onSubmit = async (values: any) => {
  const result = await selectFormField.value.validate();
  if (!result.valid) return;
  const monthArrayValue = buildAndFormatDataScope(values);
  const requestData = {
    ...monthArrayValue,
    ...collectData.data,
  } as IUploadScopeDTO;
  const data = await S2RPModule.uploadEnergyLoadsAction(
    requestData as IUploadScopeDTO
  );
  if (data && listArrImage?.length > 0) {
    await submitDocument(data);
    listArrImage = [];
    S2RPModule.getEnergyLoadFileAndDocument({
      FacilityId: collectData.data.FacilityId,
      Year: collectData.data.Year,
    });
    return;
  }
  S2RPModule.getEnergyLoadFileAndDocument({
    FacilityId: collectData.data.FacilityId,
    Year: collectData.data.Year,
  });
};
const submitDocument = async (data: any) => {
  const children = data.concat(listArrImage);
  let newListForm = groupBy(children, "MonthType");
  let fileUploads = [] as any;
  Object.values(newListForm).forEach(async (items: any) => {
    if (items.length > 1) {
      fileUploads = items.map((item: any, index: number) => {
        if (index < items.length - 1)
          return S2RPModule.uploadEnergyLoadDocumentAction({
            Id: items[0].Id,
            File: items[index + 1].file,
          });
      });
    }
  });
  const value = await Promise.all(fileUploads);
  return value;
};
const onBlurMonthField = (value: any, setFieldValue: any, field: any) => {
  if (!value || value.length < 1) return;
  const result = formatNumber(Number(value), 0);
  setFieldValue(field, result);
};
const onFocusMonthField = (value: any, setFieldValue: any, field: any) => {
  if (!value || value.length < 1) return;
  var result = removeNumberFormat(value);
  setFieldValue(field, result);
};
const onChangeMonthField = (value: any, setFieldValue: any, field: any) => {
  const result = validateInputNumberType(parseFloat(value));
  setFieldValue(field, result);
};

const onBlurMonthTestField = (value: any) => {
  collectData.data = {
    ...collectData.data,
    GridEmissionFactor: value,
  };
};

const onChangeFile = (file: any, monthValue: any) => {
  const formdata = new FormData();
  formdata.append("file", file.raw);
  listArrImage.push({ file: formdata, MonthType: monthValue + 1 });
};
const handleRemove = (file: any, uploadFiles: any, monthValue: any) => {
  if (file.Id && file.ModelId) {
    S2RPModule.deleteEnergyLoadDocumentAction({
      Id: file.Id,
      ModelId: file.ModelId,
    });
    return;
  }
  listArrImage = uploadFiles.map((item: any) => {
    const formdata = new FormData();
    formdata.append("file", item.raw);
    return { file: formdata, MonthType: monthValue + 1 };
  });
};

const __returned__ = { emits, facilityField, countryField, yearField, gridEmissionFactorField, energyLoadsForm, selectFormField, get listArrImage() { return listArrImage }, set listArrImage(v) { listArrImage = v }, months, get fileList() { return fileList }, set fileList(v) { fileList = v }, MonthType, energyLoadsDocument, schema, isReadonlyEnergyLoadsForm, energyLoadsTotalGetter, isLoadingEnergyLoadsTotal, energyLoadGridEmissionFactorGetter, Authorization, dataCountries, facilities, request, collectData, parseFile, handleChangeCountry, handleChangeFacility, handleChangeYear, handleResetForm, beforeUploadFile, handlePreview, onSubmit, submitDocument, onBlurMonthField, onFocusMonthField, onChangeMonthField, onBlurMonthTestField, onChangeFile, handleRemove, get IUploadScopeDTO() { return IUploadScopeDTO }, get IScopeTwoGridEmissionFactor() { return IScopeTwoGridEmissionFactor }, get IScopeTwoParameters() { return IScopeTwoParameters }, get AccountModule() { return AccountModule }, get MetadataModule() { return MetadataModule }, get buildAndFormatDataScope() { return buildAndFormatDataScope }, get formatNumber() { return formatNumber }, get removeNumberFormat() { return removeNumberFormat }, get validateInputNumberType() { return validateInputNumberType }, get isCSV() { return isCSV }, get MONTHS() { return MONTHS }, get S2RPModule() { return S2RPModule }, get ElMessage() { return ElMessage }, get isBuffer() { return isBuffer }, get configure() { return configure }, get Field() { return Field }, get FieldArray() { return FieldArray }, get Form() { return Form }, computed, onUnmounted, reactive, ref, onMounted, watch, get number() { return number }, get object() { return object }, get string() { return string }, get groupBy() { return groupBy } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})