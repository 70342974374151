import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex mb-6 space-x-4" }
const _hoisted_2 = { class: "inputfield inputfield__underline w-1/3" }
const _hoisted_3 = { class: "inputfield inputfield__underline w-1/3" }
const _hoisted_4 = { class: "inputfield inputfield__underline w-1/3" }
const _hoisted_5 = { class: "my-3" }
const _hoisted_6 = { class: "mt-2" }
const _hoisted_7 = ["disabled"]
const _hoisted_8 = { class: "flex justify-end mt-4" }
const _hoisted_9 = ["disabled"]
const _hoisted_10 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_upload = _resolveComponent("el-upload")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode($setup["Form"], {
      as: "el-form",
      "label-position": "top",
      "validation-schema": $setup.schema,
      "initial-values": $setup.energyLoadGridEmissionFactorGetter,
      ref: "selectFormField"
    }, {
      default: _withCtx(() => [
        _cache[0] || (_cache[0] = _createElementVNode("h1", { class: "text-left text-lg 2xl:text-xl font-bold mb-2" }, " Please Update Your Grid Consumption ", -1)),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode($setup["Field"], {
              name: "Country",
              ref: "countryField"
            }, {
              default: _withCtx(({ value, field, errorMessage }) => [
                _createVNode(_component_el_form_item, {
                  class: "inputfield",
                  error: errorMessage,
                  label: "Country"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select, _mergeProps({ class: "2xl:w-full lg:w-32 xl:w-48 md:w-52" }, field, {
                      "model-value": value,
                      placeholder: "Select",
                      onChange: (value) => $setup.handleChangeCountry(value)
                    }), {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.dataCountries, (item) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            class: "flex items-center",
                            key: item.Value,
                            label: item.Name,
                            value: item.Value
                          }, null, 8, ["label", "value"]))
                        }), 128))
                      ]),
                      _: 2
                    }, 1040, ["model-value", "onChange"])
                  ]),
                  _: 2
                }, 1032, ["error"])
              ]),
              _: 1
            }, 512)
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode($setup["Field"], {
              name: "FacilityId",
              ref: "facilityField"
            }, {
              default: _withCtx(({ value, field, errorMessage }) => [
                _createVNode(_component_el_form_item, {
                  class: "inputfield",
                  error: errorMessage,
                  label: "Facility"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select, _mergeProps({ class: "2xl:w-full lg:w-32 xl:w-48 md:w-52" }, field, {
                      "model-value": value,
                      placeholder: "Select",
                      onChange: (value) => $setup.handleChangeFacility(value)
                    }), {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.facilities.data, (item) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            class: "flex items-center",
                            key: item.Value,
                            label: item.Name,
                            value: item.Value
                          }, null, 8, ["label", "value"]))
                        }), 128))
                      ]),
                      _: 2
                    }, 1040, ["model-value", "onChange"])
                  ]),
                  _: 2
                }, 1032, ["error"])
              ]),
              _: 1
            }, 512)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode($setup["Field"], {
              name: "Year",
              ref: "yearField"
            }, {
              default: _withCtx(({ value, field, errorMessage }) => [
                _createVNode(_component_el_form_item, {
                  class: "inputfield",
                  error: errorMessage,
                  label: "Year"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_date_picker, _mergeProps(field, {
                      "model-value": value,
                      type: "year",
                      editable: false,
                      clearable: false,
                      "disabled-date": (time) => time.getTime() > Date.now(),
                      "value-format": "YYYY",
                      placeholder: "",
                      onChange: (value) => $setup.handleChangeYear(value)
                    }), null, 16, ["model-value", "disabled-date", "onChange"])
                  ]),
                  _: 2
                }, 1032, ["error"])
              ]),
              _: 1
            }, 512)
          ])
        ])
      ]),
      _: 1
    }, 8, ["validation-schema", "initial-values"]),
    _createVNode($setup["Form"], {
      as: "el-form",
      onSubmit: $setup.onSubmit,
      "label-position": "top",
      "initial-values": $setup.energyLoadsTotalGetter,
      ref: "energyLoadsForm"
    }, {
      default: _withCtx(({ isSubmitting, values, resetForm, setFieldValue }) => [
        _createElementVNode("div", {
          class: _normalizeClass(
        $setup.isLoadingEnergyLoadsTotal || isSubmitting ? 'isSubmitting-rounded' : ''
      )
        }, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode($setup["FieldArray"], {
                name: "MonthValue",
                "key-path": "id"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.months.data, (item, idx) => {
                    return (_openBlock(), _createBlock($setup["Field"], {
                      key: `MonthValue_${idx}`,
                      name: `MonthValue[${idx}]`
                    }, {
                      default: _withCtx(({ value, field, errorMessage }) => [
                        _createVNode(_component_el_form_item, {
                          class: "inputfield text-center input-append input-grid w-1/2",
                          error: errorMessage,
                          label: item.MonthName
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_input, _mergeProps({
                              controls: false,
                              id: "input-scope-energy",
                              class: [
                    'w-full ml-4',
                    $setup.isReadonlyEnergyLoadsForm && 'disabled-input',
                  ],
                              placeholder: "- - -",
                              ref_for: true
                            }, field, {
                              "model-value": value,
                              disabled: $setup.isReadonlyEnergyLoadsForm,
                              onBlur: 
                    () =>
                      $setup.onBlurMonthField(
                        value,
                        setFieldValue,
                        `MonthValue[${idx}]`
                      )
                  ,
                              onFocus: 
                    () =>
                      $setup.onFocusMonthField(
                        value,
                        setFieldValue,
                        `MonthValue[${idx}]`
                      )
                  ,
                              onInput: 
                    (val) =>
                      $setup.onChangeMonthField(val, setFieldValue, `MonthValue[${idx}]`)
                  
                            }), null, 16, ["class", "model-value", "disabled", "onBlur", "onFocus", "onInput"]),
                            _cache[1] || (_cache[1] = _createElementVNode("p", { class: "ml-4" }, "kWh", -1))
                          ]),
                          _: 2
                        }, 1032, ["error", "label"]),
                        _createVNode(_component_el_upload, {
                          "file-list": $setup.fileList[idx + 1],
                          "onUpdate:fileList": ($event: any) => (($setup.fileList[idx + 1]) = $event),
                          class: "relative custom-file-list-image-grid",
                          "auto-upload": false,
                          ref_for: true,
                          ref: "uploadRef",
                          multiple: "",
                          "on-change": (res) => $setup.onChangeFile(res,idx),
                          limit: 3,
                          "on-remove": (file,uploadFiles ) => $setup.handleRemove(file, uploadFiles, idx),
                          "on-preview": $setup.handlePreview
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("button", {
                              type: "button",
                              class: "text-blue-500 font-semibold underline mb-4 ml-10",
                              disabled: isSubmitting
                            }, " Please upload your files here ", 8, _hoisted_7)
                          ]),
                          _: 2
                        }, 1032, ["file-list", "onUpdate:fileList", "on-change", "on-remove"])
                      ]),
                      _: 2
                    }, 1032, ["name"]))
                  }), 128))
                ]),
                _: 2
              }, 1024)
            ]),
            _cache[2] || (_cache[2] = _createElementVNode("hr", { class: "border-gray-400" }, null, -1)),
            _createElementVNode("div", _hoisted_8, [
              (!$setup.isReadonlyEnergyLoadsForm)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    type: "submit",
                    class: "btn btn--green-primary",
                    disabled: isSubmitting
                  }, " Upload ", 8, _hoisted_9))
                : (_openBlock(), _createElementBlock("button", {
                    key: 1,
                    class: "btn btn--red-primary",
                    onClick: _withModifiers(() => $setup.handleResetForm(values, resetForm), ["prevent"])
                  }, " Reset ", 8, _hoisted_10))
            ])
          ])
        ], 2)
      ]),
      _: 1
    }, 8, ["initial-values"])
  ], 64))
}