import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex mb-6 space-x-4" }
const _hoisted_2 = { class: "inputfield inputfield__underline w-1/2" }
const _hoisted_3 = { class: "inputfield inputfield__underline w-1/2" }

import { computed, onMounted, reactive } from "vue";
import S2RenewableEnergyModule from "@ems/containers/ScopeTwoEmission/Table/S2RenewableEnergy.module";
import { Field, Form } from "vee-validate";
import {
  formatLocalDateTime,
  formatToUTCLocalMonth,
  formatToUTCMonth,
} from "@/utils/helpers/convertDateTime";
import MetadataModule from "@/store/modules/Metadata";

export default /*@__PURE__*/_defineComponent({
  __name: 'Renewable_Energy.Table',
  setup(__props) {

const initialValues = {
  FacilityId: "",
  Country: "",
};
const facilities = reactive<any>({ data: [] });
const setDefaultFacilitiesData = (data: any) => {
  data.unshift({
    CountryId: "",
    CountryName: "",
    CountryCode: "",
    Red: false,
    Url: "",
    Latitude: 0,
    Longitude: 0,
    Value: null,
    Name: "All",
  });
};
const dataCountries = computed(
  () => MetadataModule.dataInputManualCountriesGetter
);

const handleChangeCountry = async (value: any, setFieldValue: any) => {
  const facilitiesGetter = MetadataModule.dataFacilitiesGetter;
  facilities.data = facilitiesGetter.filter((fac) => fac.CountryId == value);
  setFieldValue("FacilityId", null);
  setDefaultFacilitiesData(facilities.data);
  if (value === null) {
    S2RenewableEnergyModule.getRenewableEnergies();
  }
};

const handleChangeFacility = async (value: any) => {
  if (value) {
    S2RenewableEnergyModule.getRenewableEnergies({ FacilityId: value });
  } else {
    S2RenewableEnergyModule.getRenewableEnergies();
  }
};
const isLoadingGetRenewableEnergies = computed(
  () => S2RenewableEnergyModule.isLoadingGetRenewableEnergiesGetter
);

const dataRenewableEnergies = computed(
  () => S2RenewableEnergyModule.dataRenewableEnergiesGetter
);

onMounted(() => {
  S2RenewableEnergyModule.getRenewableEnergies();
});

return (_ctx: any,_cache: any) => {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", null, [
    _cache[0] || (_cache[0] = _createElementVNode("h1", { class: "text-left text-lg 2xl:text-xl font-bold mb-2" }, " Please Upload RECs you have retired outside of REsuite ", -1)),
    _createVNode(_unref(Form), {
      as: "el-form",
      "label-position": "top",
      "initial-values": initialValues,
      ref: "selectFormField"
    }, {
      default: _withCtx(({ setFieldValue }) => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_unref(Field), { name: "Country" }, {
              default: _withCtx(({ value, field, errorMessage }) => [
                _createVNode(_component_el_form_item, {
                  class: "inputfield",
                  error: errorMessage,
                  label: "Country"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select, _mergeProps({ class: "2xl:w-full lg:w-32 xl:w-48 md:w-52" }, field, {
                      "model-value": value,
                      placeholder: "Select",
                      onChange: (value) => handleChangeCountry(value, setFieldValue)
                    }), {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dataCountries.value, (item) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            class: "flex items-center",
                            key: item.Value,
                            label: item.Name,
                            value: item.Value
                          }, null, 8, ["label", "value"]))
                        }), 128))
                      ]),
                      _: 2
                    }, 1040, ["model-value", "onChange"])
                  ]),
                  _: 2
                }, 1032, ["error"])
              ]),
              _: 2
            }, 1024)
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_unref(Field), { name: "FacilityId" }, {
              default: _withCtx(({ value, field, errorMessage }) => [
                _createVNode(_component_el_form_item, {
                  class: "inputfield",
                  error: errorMessage,
                  label: "Facility"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select, _mergeProps({ class: "2xl:w-full lg:w-32 xl:w-48 md:w-52" }, field, {
                      "model-value": value,
                      placeholder: "Select",
                      onChange: (value) => handleChangeFacility(value)
                    }), {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(facilities.data, (item) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            class: "flex items-center",
                            key: item.Value,
                            label: item.Name,
                            value: item.Value
                          }, null, 8, ["label", "value"]))
                        }), 128))
                      ]),
                      _: 2
                    }, 1040, ["model-value", "onChange"])
                  ]),
                  _: 2
                }, 1032, ["error"])
              ]),
              _: 1
            })
          ])
        ])
      ]),
      _: 1
    }, 512),
    _withDirectives((_openBlock(), _createBlock(_component_el_table, {
      class: "w-full custom-table",
      data: dataRenewableEnergies.value,
      stripe: "",
      height: "65vh",
      "cell-class-name": "text-cafe-dark",
      "header-row-class-name": "tableHeaderRow",
      "row-class-name": "tableRow"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          width: "150",
          prop: "EntryTime",
          label: "Entry Name"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(_unref(formatLocalDateTime)(scope.row.EntryTime)), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "Amount",
          label: "Amount"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(`${scope.row.Amount} MWh`), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          width: "150",
          prop: "ReportingMonth",
          label: "Reporting Month"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(`${_unref(formatToUTCLocalMonth)(scope.row.PeriodFrom)} - ${_unref(formatToUTCMonth)(
            scope.row.PeriodTo
          )}`), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          width: "150",
          prop: "FacilityName",
          label: "FACILITY"
        })
      ]),
      _: 1
    }, 8, ["data"])), [
      [_directive_loading, isLoadingGetRenewableEnergies.value]
    ])
  ]))
}
}

})