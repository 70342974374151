import { defineComponent as _defineComponent } from 'vue'
import {
  IScopeTwoGeneratedEnergiesRequest,
  IScopeTwoGridEmissionFactor,
  IScopeTwoParameters,
  IScopeTwoRenewableEnergiesRequest,
} from "@/models/ScopeTwoEmissions";
import AccountModule from "@/store/modules/Account";
import MetadataModule from "@/store/modules/Metadata";
import {
  buildAndFormatDataStringToNumber,
  formatNumber,
  removeNumberFormat,
  validateInputNumberType,
} from "@/utils/helpers/buildDataFormat";
import { isCSV } from "@/utils/helpers/checkFileType";
import S2RPModule from "@ems/containers/Dashboard/TheForm/S2RP.module";
import { ElMessage } from "element-plus/lib/components/message";
import { configure, Field, Form } from "vee-validate";
import { computed, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { number, object, string, array } from "yup";
import {
  endDateOfMonth,
  startDateOfMonth,
} from "@/utils/helpers/convertDateTime";
import BaseSvgIcon from "@/components/BaseSvgIcon.vue";
import S2RenewableEnergyModule from "@ems/containers/ScopeTwoEmission/Table/S2RenewableEnergy.module";
import { ICountries } from "@/models/Metadata";
import RetireModule from "@ems/containers/Dashboard/TheForm/RetireRECs.module";
import dayjs from "dayjs";
interface RawFile {
  name: string;
  url: string;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'Renewables_Energy.Form',
  emits: ["resetFormRenewableEnergyOnClickRow"],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const emits = __emit;
configure({
  validateOnBlur: false,
  validateOnChange: false,
  validateOnInput: false,
  validateOnModelUpdate: true,
});
const facilityField = ref();
const countryField = ref();
const selectFormField = ref();
let listArrImage = [] as any;
const registry = ref([
  { Value: 0, Name: "IREC" },
  { Value: 1, Name: "TIGR" },
]);
const schema = object({
  Country: string().required("Country is a required field").label("Country"),
  FacilityId: string()
    .required("Facility is a required field")
    .label("Facility"),
  MonthRange: array().required().nullable().label("MonthRange"),
  Value: string().required().label("Total Volume of Generation"),
  MonthRangeReport: array().required().nullable().label("MonthRange"),
});
const uploadRef = ref<any>();
const router = useRouter();

const dataCountries = computed(() => MetadataModule.dataCountriesGetter);
const facilities = reactive<any>({ data: [] });
const dataFuel = computed(
  () => S2RenewableEnergyModule.dataFuelRenewableEnergiesGetter
);
const request = reactive<any>({ params: {} });
const energyRenewableEmissionFactor = computed(
  () => S2RPModule.energyGeneratedEmissionFactorGetter
);
const allCountries = computed(() => MetadataModule.allCountriesGetter);

const disabledMonth = (time: Date) => {
  return (
    time.getTime() < vintageStart.value ||
    time.getTime() >
      dayjs(vintageStart.value).add(21, "months").toDate().getTime()
  );
};

onMounted(() => {
  const facilitiesGetter = MetadataModule.dataFacilitiesGetter;
  facilities.data = facilitiesGetter.filter(
    (fac) => fac.CountryId == energyRenewableEmissionFactor.value.Country
  );
  S2RenewableEnergyModule.fetchS2RenewablePercentages();
  S2RenewableEnergyModule.getFuelRenewableEnergies();
  MetadataModule.getAllCountryAction();
  filterOptionCountry();
  RetireModule.getRetireRecAndRenewable({ Year: new Date().getFullYear() });
});
const handleChangeCountry = async (value: any) => {
  facilityField.value.reset();
  const facilitiesGetter = MetadataModule.dataFacilitiesGetter;
  facilities.data = facilitiesGetter.filter((fac) => fac.CountryId == value);
};

const handleChangeFacility = async (value: any) => {
  //TOdo
};

const vintageStart = ref(Date.now());
const handleVintageTime = (val: any) => {
  vintageStart.value = val[0].getTime();
};

const beforeUploadFile = (file: any) => {
  const checkCSV = isCSV(file.name);
  const isLt2M = file.size / 1024 / 1024 < 2;

  if (!checkCSV) {
    ElMessage.error("File must be .csv, .xlsx, .xls format!");
  }
  if (!isLt2M) {
    ElMessage.error("File size can not exceed 2MB!");
  }
  return checkCSV && isLt2M;
};

const onSubmit = async (values: any, actions: any) => {
  const requestCreateRenewable = {
    FacilityId: values.FacilityId,
    Amount: buildAndFormatDataStringToNumber(values.Value),
    EnergyType: values.TypeofRECs,
    Locations: values.LocationOfRECs,
    PeriodFrom: startDateOfMonth(values.MonthRangeReport[0]),
    PeriodTo: endDateOfMonth(values.MonthRangeReport[1]),
    VintageFrom: startDateOfMonth(values.MonthRange[0]),
    VintageTo: endDateOfMonth(values.MonthRange[1]),
    RegistryType: values.RegistryId,
  } as IScopeTwoRenewableEnergiesRequest;
  await createRenewableEnergy(requestCreateRenewable);
  RetireModule.getRetireRecAndRenewable({ Year: new Date().getFullYear() });
  actions.resetForm();
  uploadRef.value!.submit();
  listArrImage = [];
};
const createRenewableEnergy = async (
  requestCreateRenewable: IScopeTwoRenewableEnergiesRequest
) => {
  const data = await S2RenewableEnergyModule.createRenewableEnergy(
    requestCreateRenewable
  );
  S2RenewableEnergyModule.getRenewableEnergies();
  if (data && listArrImage?.length > 0) {
    parseDataRequestFile(data.Id, listArrImage);
  }
};
const parseDataRequestFile = async (
  RenewableEnergyId: string,
  listArrImage: any
) => {
  const newListForm = listArrImage.map((item: any, index: any) => {
    index = new FormData();
    index.append("file", item);
    return index;
  });

  const fileUploads = newListForm.map((item: any) =>
    S2RenewableEnergyModule.uploadFileRenewableEnergy({
      Id: RenewableEnergyId,
      data: item,
    })
  );
  await Promise.all(fileUploads);
  return newListForm;
};
const onBlurValueField = (value: any, setFieldValue: any) => {
  if (!value || value.length < 1) return;
  const result = formatNumber(Number(value), 0);
  setFieldValue("Value", result);
};
const onFocusValueField = (value: any, setFieldValue: any) => {
  if (!value || value.length < 1) return;
  var result = removeNumberFormat(value);
  setFieldValue("Value", result);
};
const onChangeValueField = (value: any, setFieldValue: any) => {
  const result = validateInputNumberType(parseFloat(value));
  setFieldValue("Value", result);
};

const handleUploadImageSuccess = (res: any, file: any, field: any) => {
  console.log(URL.createObjectURL(file.raw));
};
const beforeUploadImage = (file: any) => {
  const isJPG = true;
  const isLt2M = file.size / 1024 / 1024 < 2;

  if (!isJPG) {
    ElMessage.error("File upload must be JPG format!");
  }
  if (!isLt2M) {
    ElMessage.error("Avatar picture size can not exceed 2MB!");
  }
  return isJPG && isLt2M;
};
const onChangeFile = (file: any) => {
  listArrImage.push(file.raw);
};
const handleRemove = (file: any, uploadFiles: any) => {
  const arr = uploadFiles.map((item: any) => {
    return item.raw;
  });
  listArrImage = arr;
};

const options = ref<ICountries[]>([]);
const remoteMethod = (query: string) => {
  if (query) {
    setTimeout(() => {
      options.value = allCountries.value.filter((item) => {
        return item.Name.toLowerCase().includes(query.toLowerCase());
      });
    }, 200);
  } else if (query === "") {
    options.value = allCountries.value;
  } else {
    options.value = [];
  }
};
watch(allCountries, () => {
  filterOptionCountry();
});
const filterOptionCountry = () => {
  options.value = allCountries.value.map((item) => {
    return { Value: `${item.Value}`, Name: `${item.Name}` };
  });
};

const __returned__ = { emits, facilityField, countryField, selectFormField, get listArrImage() { return listArrImage }, set listArrImage(v) { listArrImage = v }, registry, schema, uploadRef, router, dataCountries, facilities, dataFuel, request, energyRenewableEmissionFactor, allCountries, disabledMonth, handleChangeCountry, handleChangeFacility, vintageStart, handleVintageTime, beforeUploadFile, onSubmit, createRenewableEnergy, parseDataRequestFile, onBlurValueField, onFocusValueField, onChangeValueField, handleUploadImageSuccess, beforeUploadImage, onChangeFile, handleRemove, options, remoteMethod, filterOptionCountry, get IScopeTwoGeneratedEnergiesRequest() { return IScopeTwoGeneratedEnergiesRequest }, get IScopeTwoGridEmissionFactor() { return IScopeTwoGridEmissionFactor }, get IScopeTwoParameters() { return IScopeTwoParameters }, get IScopeTwoRenewableEnergiesRequest() { return IScopeTwoRenewableEnergiesRequest }, get AccountModule() { return AccountModule }, get MetadataModule() { return MetadataModule }, get buildAndFormatDataStringToNumber() { return buildAndFormatDataStringToNumber }, get formatNumber() { return formatNumber }, get removeNumberFormat() { return removeNumberFormat }, get validateInputNumberType() { return validateInputNumberType }, get isCSV() { return isCSV }, get S2RPModule() { return S2RPModule }, get ElMessage() { return ElMessage }, get configure() { return configure }, get Field() { return Field }, get Form() { return Form }, computed, onMounted, onUnmounted, reactive, ref, watch, get useRouter() { return useRouter }, get number() { return number }, get object() { return object }, get string() { return string }, get array() { return array }, get endDateOfMonth() { return endDateOfMonth }, get startDateOfMonth() { return startDateOfMonth }, BaseSvgIcon, get S2RenewableEnergyModule() { return S2RenewableEnergyModule }, get ICountries() { return ICountries }, get RetireModule() { return RetireModule }, get dayjs() { return dayjs } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})